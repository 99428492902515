import "./banner.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsBanner extends PropsWithChildren<any> {
  title: string;
  background: string;
  isOverflow?: boolean;
  color?: string;
}

const Banner: React.FC<PropsBanner> = (props: PropsBanner) => {
  const color = props.color ? props.color : "#A3BCC4";
  return (
    <div className={`o-banner ${props.isOverflow ? "o-banner--overflow" : ""}`}>
      <div className="banner-background">
        <img
          src={props.background}
          className={"cut"}
          width={600}
          height={300}
        />
      </div>
      <div className={"container"}>
        <div className="m-banner">
          <p className={"h2 is-white is-text-shadow"}>{props.title}</p>
          {!props.children && (
            <a
              href={
                "https://www.treebole-clinicas.net/es/customer/requestappo/cusrc/g84fzxc8vvaguap"
              }
              target={"_blank"}
              rel={"noopener noreferrer nofollow"}
              className={"a-button"}
              style={{
                backgroundColor: `${color}`,
                color: "#fff",
              }}
            >
              {props.ctaText && <>{props.ctaText}</>}
              {!props.ctaText && <> Pide cita</>}
            </a>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Banner;
